import React, { useState, useEffect } from 'react';
import {
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    SelectInput,
} from 'react-admin';
import { Divider, Box } from '@mui/material';
import { FileInput, FileField } from 'react-admin';


export const RegisterInputs = () => {
    return (
        <Box flex="1" mt={-1}>

            <Box display="flex">
                <ReferenceInput source="field" reference="registers" editable fullWidth sort={{ field: 'name', order: 'ASC' }}>
                    <SelectInput optionText="name" fullWidth />
                </ReferenceInput>
                <Spacer />
                <SelectInput
                    source="is_historical"
                    choices={[
                        { id: 'true', name: 'True' },
                        { id: 'false', name: 'False' }
                    ]}
                    fullWidth
                    defaultValue="false"
                    label="Is Historical"
                />
                <Spacer />
                <TextInput source="historical_col" fullWidth label="Historical Column" />
            </Box>
            <Divider />

            <Box display="flex">
                <TextInput source="cols_to_exclude" fullWidth multiline rows={2} label="Columns to exclude. If many comma separate. Optional" />
                <Spacer />
                <TextInput source="drop_dp_val" fullWidth multiline rows={2} label="Set values to null if value is for any data point. Optional" />
            </Box>

            <Box mt={2} >
                <FileInput source="file_name" fullWidth maxSize={25000000} isRequired accept='text/csv'>
                    <FileField source="src" title="title" />
                </FileInput>
            </Box>

        </Box>
    );
};

const Spacer = () => <Box width={20} component="span" />;
