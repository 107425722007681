/* eslint-disable import/no-anonymous-default-export */
import * as React from 'react';
import {
    FilterList,
    FilterLiveSearch,
    FilterListItem,
    useGetIdentity,
    useGetList,
} from 'react-admin';
import { Box, Chip } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

import {
    endOfYesterday,
    startOfWeek,
    startOfMonth,
    subMonths,
    subWeeks,
} from 'date-fns';

import { Status } from '../misc/Status';

export const RegisterListFilter = () => {
    const { identity } = useGetIdentity();
    const { data } = useGetList('tags', {
        pagination: { page: 1, perPage: 10 },
        sort: { field: 'name', order: 'ASC' },
    });


    return (
        <Box width="13em" minWidth="13em" order={-1} mr={2} mt={7}>
            <FilterLiveSearch
                source="fts@fts"
                sx={{
                    display: 'block',
                    '& .MuiFilledInput-root': { width: '100%' },
                }}
            />
            <FilterList label="Last seen" icon={<AccessTimeIcon />}>
                <FilterListItem
                    label="Today"
                    value={{
                        'last_updated_at@gte': endOfYesterday().toISOString(),
                        'last_updated_at@lte': undefined,
                    }}
                />
                <FilterListItem
                    label="This week"
                    value={{
                        'last_updated_at@gte': startOfWeek(new Date()).toISOString(),
                        'last_updated_at@lte': undefined,
                    }}
                />
                <FilterListItem
                    label="Last week"
                    value={{
                        'last_updated_at@gte': subWeeks(
                            startOfWeek(new Date()),
                            1
                        ).toISOString(),
                        'last_updated_at@lte': startOfWeek(new Date()).toISOString(),
                    }}
                />
                <FilterListItem
                    label="This month"
                    value={{
                        'last_updated_at@gte': startOfMonth(new Date()).toISOString(),
                        'last_updated_at@lte': undefined,
                    }}
                />
                <FilterListItem
                    label="Last month"
                    value={{
                        'last_updated_at@gte': subMonths(
                            startOfMonth(new Date()),
                            1
                        ).toISOString(),
                        'last_updated_at@lte': startOfMonth(new Date()).toISOString(),
                    }}
                />
                <FilterListItem
                    label="Earlier"
                    value={{
                        'last_updated_at@gte': undefined,
                        'last_updated_at@lte': subMonths(
                            startOfMonth(new Date()),
                            1
                        ).toISOString(),
                    }}
                />
            </FilterList>
            <FilterList label="Status" icon={<TrendingUpIcon />}>
                <FilterListItem
                    label={
                        <>
                            Open <Status status="open" />
                        </>
                    }
                    value={{ status: 'open' }}
                />
                <FilterListItem
                    label={
                        <>
                            Rejected <Status status="rejected" />
                        </>
                    }
                    value={{ status: 'rejected' }}
                />
                
                <FilterListItem
                    label={
                        <>
                            Released <Status status="released" />
                        </>
                    }
                    value={{ status: 'released' }}
                />
            </FilterList>
           

            <FilterList label="Tags" icon={<LocalOfferIcon />}>
                {data &&
                    data.map(record => (
                        <FilterListItem
                            key={record.id}
                            label={
                                <Chip
                                    label={record?.name}
                                    size="small"
                                    style={{
                                        backgroundColor: record?.color,
                                        border: 0,
                                        cursor: 'pointer',
                                    }}
                                />
                            }
                            value={{ 'tags@cs': `{${record.id}}` }}
                        />
                    ))}
            </FilterList>

            <FilterList
                label="Created By"
                icon={<SupervisorAccountIcon />}
            >
                <FilterListItem
                    label="Me"
                    value={{ user_id: identity && identity.id }}
                />
            </FilterList>
        </Box>
    );
};
