import * as React from 'react';
import {
    Typography,
    Card,
    CardContent,
    CardActions,
    Button,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import CodeIcon from '@mui/icons-material/Code';

export const Welcome = () => (
    <Card
        sx={{
            background: `#c5dedd`,
            color: 'rgba(0, 0, 0, 0.87)',
            padding: '1em',
            marginBottom: '1em',
            marginTop: '2em',
            [`& .MuiCardActions-root`]: {
                p: 2,
                mt: -2,
                mb: -1,
                flexDirection: 'column',
                '& a': {
                    mb: 1,
                    color: 'rgba(0, 0, 0, 0.87)',
                    backgroundColor: 'white',
                    marginLeft: '0 !important',
                },
            },
        }}
    >
        <CardContent>
            <Typography variant="h5" gutterBottom>
                Firmable Admin
            </Typography>
            <Typography gutterBottom>
                This app is designed for submitting change requests to our data engineering team. Changes will be implemented in the very next release cycle.
                Please refer to the Notion page for instructions on how to use the app.
            </Typography>

            <Typography gutterBottom>
                Please refer to the Notion page for instructions on how to use the app.
            </Typography>

            <Typography gutterBottom>
                This app is managed by Data Engineering team. Access to this app is granted by Data Engineering. If you need access, please reach out.
            </Typography>
        </CardContent>
        <CardActions>
            <Button
                variant="contained"
                fullWidth
                href="https://www.notion.so/Admin-App-8b33fda2950844ecb38b64e5dd8babe1"
                target='_blank'
                startIcon={<HomeIcon />}
            >
                Notion Page - How to use this app
            </Button>

            <Button
                variant="contained"
                fullWidth
                href="https://staging.firmable.com/dashboard/sales"
                target='_blank'
                startIcon={<CodeIcon />
                }

            >
                Staging App - Firmable
            </Button>

            <Button
                variant="contained"
                fullWidth
                href="https://app.firmable.com/dashboard/sales"
                target='_blank'
                startIcon={<CodeIcon />}
            >
                Production App - Firmable
            </Button>

        </CardActions>
    </Card>
);