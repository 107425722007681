import React, { useState, useEffect } from 'react';
import {
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    SelectInput,
} from 'react-admin';
import { Divider, Box } from '@mui/material';

const validatePersonId = (value:any) => {
    if (!(value.startsWith('fp0'))) {
        return 'Person ID must start with "fp0"';
    } else if (value.length !== 14) {
        return 'Person ID must be 14 characters long';
    }
    return undefined;
};

const validateCompanyId = (value:any) => {
    if (!value) {
        return undefined; // No validation if value is empty
    }
    if (!(value.startsWith('f0'))) {
        return 'Company ID must start with "f0"';
    } else if (value.length !== 13) {
        return 'Company ID must be 13 characters long';
    }
    return undefined;
};

export const CRMInputs = () => {
    const [profileType, setProfileType] = useState('company');

    const handleProfileTypeChange = (event:any) => {
        setProfileType(event.target.value);
    };

    const getValidationFunction = () => {
        if (profileType === 'person') {
            return validatePersonId;
        } else if (profileType === 'company') {
            return validateCompanyId;
        }
        return undefined;
    };

    return (
        <Box flex="1" mt={-1}>
            <Box display="flex" >
                <SelectInput
                    source="profile_type"
                    choices={[
                        { id: 'company', name: 'Company' },
                        { id: 'person', name: 'Person' },
                        { id: 'filterOption', name: 'Filter Option' }
                    ]}
                    fullWidth
                    onChange={handleProfileTypeChange}
                    defaultValue="company"
                />
                <Spacer />

                <SelectInput
                    source="action"
                    choices={[
                        { id: 'add', name: 'Add' },
                        { id: 'update', name: 'Update' },
                        { id: 'disable', name: 'Disable' }
                    ]}
                    fullWidth required
                />
                <Spacer />
                <TextInput
                    source="profile_id"
                    fullWidth
                    label="Company ID or Person ID or Filter Option"
                    required
                    validate={getValidationFunction()} // Assign dynamic validation function
                />
            </Box>
            <Box display="flex">
            <ReferenceInput source="field" reference="crmFields" editable fullWidth sort={{ field: 'name', order: 'ASC' }} filter={{ profile_type: profileType }}>
                    <SelectInput optionText="name" fullWidth />
                </ReferenceInput>
            </Box>
            <Divider />
            <Box mt={2} >
                <TextInput source="value" fullWidth required multiline rows={5} label="Enter your value here. If many comma separate. For disabling add your comments" />
            </Box>
        </Box>
    );
};

const Spacer = () => <Box width={20} component="span" />;
