import {
    TextField,
    EmailField,
    DateField,
    ReferenceManyField,
    EditButton,
    ShowButton,
    useListContext,
    ReferenceField,
    FunctionField,
    useRecordContext,
} from 'react-admin';
import { Box, Typography, Divider, List, ListItem } from '@mui/material';

import { RegRequests, User } from '../types';


export const RegisterAside = ({ link = 'edit' }: { link?: 'edit' | 'show' }) => {
    const record = useRecordContext<RegRequests>();

    return (
        <Box ml={4} width={250} minWidth={250}>
            <Box textAlign="center" mb={2}>
                {link === 'edit' ? (
                    <EditButton label="Edit Request" />
                ) : (
                    <ShowButton label="Show Request" />
                )}
            </Box>

            <Box mb={2}>
                <Typography variant="subtitle2">Basic Info</Typography>
                <Divider />
                <Typography variant="body2">
                    Request ID: {record && `${record.id}`}
                </Typography>

                <Typography variant="body2">
                    Rows Processed: {record && `${record.rows_processed}`}
                </Typography>

                <Typography variant="body2">
                    File Name: {record && `${record.file_name}`}
                </Typography>

                <Typography variant="body2">
                    Is Historical: {record && `${record.is_historical}`}
                </Typography>

                <Typography variant="body2">
                    Historical Column: {record && `${record.historical_col}`}
                </Typography>

                <Typography variant="body2">
                    Columns to Exclude: {record && `${record.cols_to_exclude}`}
                </Typography>

                <Typography variant="body2">
                    Drop Data Points: {record && `${record.drop_dp_val}`}
                </Typography>

            </Box>


            <Typography variant="subtitle2">Background</Typography>
            <Divider />
            <Typography variant="body2" mt={2}>
                {record && record.background}
            </Typography>
            <Box mt={1} mb={3}>
            <Typography
                    component="span"
                    variant="body2"
                    color="textSecondary"
                >
                    Created By:
                </Typography>{' '}
                <ReferenceField
                    record={record}
                    source="user_id"
                    reference="crmUsers" 
                    link={false}  
                >
                    <TextField source="first_name" />
                </ReferenceField>
                <br />

                <Typography
                    component="span"
                    variant="body2"
                    color="textSecondary"
                >
                    Created At:
                </Typography>{' '}
                <DateField
                    source="created_at"
                    options={{ year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }}
                    color="textSecondary"
                />
                <br />
                <Typography
                    component="span"
                    variant="body2"
                    color="textSecondary"
                >
                    Updated At:
                </Typography>{' '}
                <DateField
                    source="last_updated_at"
                    options={{ year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }}
                    color="textSecondary"
                />
                <br />
            </Box>
        </Box>
    );
};
