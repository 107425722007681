/* eslint-disable import/no-anonymous-default-export */
import * as React from 'react';
import {
    List as RaList,
    SimpleListLoading,
    ReferenceField,
    TextField,
    useListContext,
    ExportButton,
    SortButton,
    TopToolbar,
    CreateButton,
    Pagination,
    useGetIdentity,
    BulkActionsToolbar,
    BulkDeleteButton,
    RecordContextProvider,
    BulkExportButton,
} from 'react-admin';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Checkbox,
    Typography,
} from '@mui/material';
import { Avatar } from './Avatar';

import { Link } from 'react-router-dom';
import { formatDistance } from 'date-fns';

import { Status } from '../misc/Status';
import { TagsList } from './TagsList';
import { CRMListFilter } from './CRMListFilter';
import { CRM, User } from '../types';
import { SpaceBar } from '@mui/icons-material';

const CRMListContent = () => {
    const {
        data: crm,
        isLoading,
        onToggleItem,
        selectedIds,
    } = useListContext<CRM>();
    if (isLoading) {
        return <SimpleListLoading hasLeftAvatarOrIcon hasSecondaryText />;
    }
    const now = Date.now();

    return (
        <>
            <BulkActionsToolbar>
                <BulkExportButton />
            </BulkActionsToolbar>
            <List>
                {crm.map(row => (
                    <RecordContextProvider key={row.id} value={row}>
                        <ListItem
                            button
                            component={Link}
                            to={`/crm/${row.id}/show`}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={selectedIds.includes(row.id)}
                                    tabIndex={-1}
                                    disableRipple
                                    onClick={e => {
                                        e.stopPropagation();
                                        onToggleItem(row.id);
                                    }}
                                />
                            </ListItemIcon>
                            <Avatar record={row} />
                            &nbsp;&nbsp;&nbsp;
                            <ListItemText
                                primary={
                                    <>
                                        <Typography variant="body2">
                                            <ReferenceField
                                                record={row}
                                                source="field"
                                                reference="crmFields"
                                                link={false}
                                            >
                                                <TextField source="name" />
                                            </ReferenceField>
                                        </Typography>
                                    </>
                                }


                                secondary={
                                    <>
                                        {
                                            row.action.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
                                        } for {row.profile_type} with id {row.profile_id}

                                        {' '}
                                        &nbsp;&nbsp;
                                        <TagsList />
                                    </>
                                }
                            />

                            <ListItemSecondaryAction>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    last activity{' '}
                                    {formatDistance(
                                        new Date(row.last_updated_at),
                                        now
                                    )}{' '}
                                    ago <Status status={row.status} />
                                </Typography>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </RecordContextProvider>
                ))}
            </List>
        </>
    );
};

const CRMListActions = () => (
    <TopToolbar>
        <SortButton fields={['id', 'last_updated_at']} />
        <ExportButton />
        <CreateButton
            variant="contained"
            label="New Request"
            sx={{ marginLeft: 2 }}
        />
    </TopToolbar>
);

export const CRMList = () => {
    const { identity } = useGetIdentity();
    return identity ? (
        <RaList
            actions={<CRMListActions />}
            aside={<CRMListFilter />}
            perPage={25}
            pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
            sort={{ field: 'last_updated_at', order: 'DESC' }}
        >
            <CRMListContent />
        </RaList>
    ) : null;
};
