import * as React from 'react';
import { EditBase, Form, Toolbar, useEditContext, SaveButton } from 'react-admin';
import { Card, CardContent, Box } from '@mui/material';

import { RegisterInputs } from './RegisterInputs';
import { RegisterAside } from './RegisterAside';
import { Registers } from '../types';


export const RegisterEdit = () => (
    <EditBase redirect="show">
        <RegisterEditContent />
    </EditBase>
);

const RegisterEditContent = () => {
    const { isLoading, record } = useEditContext<Registers>();

    if (isLoading || !record) return null;
    return (
        <Box mt={2} display="flex">
            <Box flex="1">
                <Form>
                    <Card>
                        <CardContent>
                            <Box>
                                <Box display="flex">
                                    <RegisterInputs />
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar>
                        <SaveButton type='button' transform={(data) => {
                                const {fts, ...rest} = data;
                                return rest;
                            }}/>
                        </Toolbar>
                    </Card>
                </Form>
            </Box>
            <RegisterAside link="show" />
        </Box>
    );
};