import * as React from 'react';
import { Avatar as MuiAvatar } from '@mui/material';
import { useRecordContext } from 'react-admin';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import FilterListIcon from '@mui/icons-material/FilterList';
import PersonIcon from '@mui/icons-material/Person';

import { CRM } from '../types';

export const Avatar = (props: { record?: CRM }) => {
    const record = useRecordContext<CRM>(props);

    
    if (!record) return null;

     if (record.profile_type === 'company' || props.record?.profile_type === 'company') {
        return (
            <MuiAvatar>
                <CorporateFareIcon />
            </MuiAvatar>
        );
    }
    if (record.profile_type === 'person' || props.record?.profile_type === 'person') {
        return (
            <MuiAvatar>
                <PersonIcon />
            </MuiAvatar>
        );
    }
    return (
        <MuiAvatar>
            <FilterListIcon />
        </MuiAvatar>
    );
};