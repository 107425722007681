import {
    TextField,
    EmailField,
    DateField,
    ReferenceManyField,
    EditButton,
    ShowButton,
    useListContext,
    ReferenceField,
    FunctionField,
    useRecordContext,
} from 'react-admin';
import { Box, Typography, Divider, List, ListItem } from '@mui/material';
import { TagsListEdit } from './TagsListEdit';

import { CRM, User } from '../types';


export const CRMAside = ({ link = 'edit' }: { link?: 'edit' | 'show' }) => {
    const record = useRecordContext<CRM>();
    const actions = [
        { id: 'add', name: 'Add' },
        { id: 'update', name: 'Update' },
        { id: 'disable', name: 'Disable' }
    ];
    const profile_types=[
        { id: 'company', name: 'Company' },
        { id: 'person', name: 'Person' },
        { id: 'filterOption', name: 'Filter Option' }
    ];

    return (
        <Box ml={4} width={250} minWidth={250}>
            <Box textAlign="center" mb={2}>
                {link === 'edit' ? (
                    <EditButton label="Edit Request" />
                ) : (
                    <ShowButton label="Show Request" />
                )}
            </Box>

            <Box mb={2}>
                <Typography variant="subtitle2">Basic Info</Typography>
                <Divider />
                <Typography variant="body2">
                    Request ID: {record && `${record.id}`}
                </Typography>

                <Typography variant="body2">
                    Profile Type: {record && profile_types.find((p) => p.id === record.profile_type)?.name}
                </Typography>
            
                <Typography variant="body2">
                    Action: {record && actions.find((a) => a.id === record.action)?.name}
                </Typography>

                <Typography variant="body2">
                    Value: {record && record.value}
                </Typography>

            </Box>


            <Typography variant="subtitle2">Background</Typography>
            <Divider />
            <Typography variant="body2" mt={2}>
                {record && record.background}
            </Typography>
            <Box mt={1} mb={3}>
            <Typography
                    component="span"
                    variant="body2"
                    color="textSecondary"
                >
                    Created By:
                </Typography>{' '}
                <ReferenceField
                    record={record}
                    source="user_id"
                    reference="crmUsers" 
                    link={false}  
                >
                    <TextField source="first_name" />
                </ReferenceField>
                <br />

                <Typography
                    component="span"
                    variant="body2"
                    color="textSecondary"
                >
                    Created At:
                </Typography>{' '}
                <DateField
                    source="created_at"
                    options={{ year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }}
                    color="textSecondary"
                />
                <br />
                <Typography
                    component="span"
                    variant="body2"
                    color="textSecondary"
                >
                    Updated At:
                </Typography>{' '}
                <DateField
                    source="last_updated_at"
                    options={{ year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }}
                    color="textSecondary"
                />
                <br />
            </Box>
            <Box mb={3}>
                <Typography variant="subtitle2">Tags</Typography>
                <Divider />
                <TagsListEdit />
            </Box>
        </Box>
    );
};
