import {
    ShowBase,
    TextField,
    ReferenceField,
    ReferenceManyField,
    useShowContext,
} from 'react-admin';
import { Box, Card, CardContent, Typography } from '@mui/material';

import { RegisterAside } from './RegisterAside';
import { NotesIterator } from '../notes';
import { CRM } from '../types';

export const RegisterShow = () => (
    <ShowBase>
        <RegisterShowContent />
    </ShowBase>
);

const RegisterShowContent = () => {
    const { record, isLoading } = useShowContext<CRM>();
    if (isLoading || !record) return null;
    return (
        <Box mt={2} display="flex">
            <Box flex="1">
                <Card>
                    <CardContent>
                        <Box display="flex">
                            <Box ml={2} flex="1">
                                <Typography variant="body2">
                                    Register: {record && <ReferenceField
                                        record={record}
                                        source="field"
                                        reference="registers" 
                                        link={false}
                                    >
                                        <TextField source="name" />
                                    </ReferenceField>}
                                </Typography>
                                <Typography variant="body2">
                                    {record.profile_id}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="body2">
                                    {record.status.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                </Typography>
                            </Box>
                        </Box>
                        <ReferenceManyField
                            target="id"
                            reference="regNotes"
                            sort={{ field: 'date', order: 'DESC' }}
                        >
                            <NotesIterator showStatus reference="crm" />
                        </ReferenceManyField>
                    </CardContent>
                </Card>
            </Box>
            <RegisterAside />
        </Box>
    );
};