import React from 'react';
import { Tabs, Tab, Toolbar, AppBar, Box, Typography } from '@mui/material';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { UserMenu, Logout, LoadingIndicator } from 'react-admin';

const Header = () => {
    const location = useLocation();

    let currentPath = '/';

    if (!!matchPath('/crm/*', location.pathname)) {
        currentPath = '/crm';
    }
    else if (!!matchPath('/dashboard/*', location.pathname)) {
        currentPath = '/dashboard';
    }

    else if (!!matchPath('/regrequests/*', location.pathname)) {
        currentPath = '/regrequests';
    }

    else if (!!matchPath('/llm/*', location.pathname)) {
        currentPath = '/llm';
    }

    return (
        <Box component="nav" sx={{ flexGrow: 1 }}>
            <AppBar position="static" color="primary">
                <Toolbar variant="dense">
                    <Box flex={1} display="flex" justifyContent="space-between">
                        <Box display="flex" alignItems="center">
                            <Box
                                component="img"
                                sx={{ marginRight: '1em', height: 30 }}
                                src={
                                    'https://firmable.com/wp-content/uploads/2023/03/logo-final-02-ai-2.svg'
                                }
                                alt="Firmable Logo"
                            />
                            <Typography component="span" variant="h4"  >
                                Admin
                            </Typography>
                        </Box>
                        <Box>
                            <Tabs
                                value={currentPath}
                                aria-label="Navigation Tabs"
                                indicatorColor="secondary"
                                textColor="inherit"
                            >
                                <Tab
                                    label={'Dashboard'}
                                    component={Link}
                                    to="/dashboard"
                                    value="/dashboard"
                                />

                                <Tab
                                    label={'Change Request'}
                                    component={Link}
                                    to="/crm"
                                    value="/crm"
                                />

                                <Tab
                                    label={'Registers'}
                                    component={Link}
                                    to="/regrequests"
                                    value="/regrequests"
                                />

                                <Tab
                                    label={'LLM Playground'}
                                    component={Link}
                                    to="/llm"
                                    value="/llm"
                                />

                            </Tabs>
                        </Box>
                        <Box display="flex">
                            <LoadingIndicator
                                sx={{
                                    '& .RaLoadingIndicator-loader': {
                                        marginTop: 2,
                                    },
                                }}
                            />
                            <UserMenu>
                                <Logout />
                            </UserMenu>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
};

export default Header;
