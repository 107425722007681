import * as React from 'react';
import { Grid } from '@mui/material';

import { Welcome } from './Welcome';
// import { LatestNotes } from './LatestNotes';

export const Dashboard = () => (
    <Grid container spacing={2} mt={1}>
        <Grid>
            <Welcome />
        </Grid>
        {/* <Grid item xs={12} md={6}>
            <LatestNotes />
        </Grid> */}
    </Grid>
);