import * as React from 'react';
import { EditBase, Form, Toolbar, useEditContext, SaveButton } from 'react-admin';
import { Card, CardContent, Box } from '@mui/material';

import { Avatar } from './Avatar';
import { CRMInputs } from './CRMInputs';
import { CRMAside } from './CRMAside';
import { CRM } from '../types';


export const CRMEdit = () => (
    <EditBase redirect="show">
        <CRMEditContent />
    </EditBase>
);

const CRMEditContent = () => {
    const { isLoading, record } = useEditContext<CRM>();


    if (isLoading || !record) return null;
    return (
        <Box mt={2} display="flex">
            <Box flex="1">
                <Form>
                    <Card>
                        <CardContent>
                            <Box>
                                <Box display="flex">
                                    <Box mr={2}>
                                        <Avatar />
                                    </Box>
                                    <CRMInputs />
                                </Box>
                            </Box>
                        </CardContent>
                        
                        <Toolbar>
                        </Toolbar>
                    </Card>
                </Form>
            </Box>
            <CRMAside link="show" />
        </Box>
    );
};