
import React, { useState } from 'react';
import { Typography, Card, CardContent, Button, TextareaAutosize, Box } from '@mui/material';

export const ExtensionDashboard = () => {
    const [name, setName] = useState('');
    const [gender, setGender] = useState<string | null>(null);
    const [error, setError] = useState<string>(''); // State for error message

    const fetchData = async () => {
        try {
            const response = await fetch(`https://api.genderize.io?name=${name}`);
            if (!response.ok) {
                throw new Error('Failed to fetch gender prediction');
            }
            const data = await response.json();
            setGender(data.gender);
            setError('');
        } catch (error) {
            console.error('Error fetching data:', error);
            setError('Failed to fetch gender prediction. Please try again later.');
        }
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handleFetchData = () => {
        fetchData();
    };

    const handleAddAnother = () => {
        setName('');
        setGender(null);
        setError('');
    };

    return (
        <Card
            sx={{
                background: `#c5dedd`,
                color: 'rgba(0, 0, 0, 0.87)',
                padding: '1em',
                marginBottom: '1em',
                marginTop: '2em',
            }}
        >
            <CardContent>
                <Typography variant="h5" gutterBottom>
                    Enter Name
                </Typography>
                <TextareaAutosize
                    minRows={4} // Set minimum rows to make it appear larger
                    placeholder="Enter Name" // Placeholder text
                    value={name}
                    onChange={handleNameChange}
                    style={{ width: '100%' }} // Set width to 100%
                />
                {error && (
                    <Typography variant="body2" color="error" gutterBottom>
                        {error}
                    </Typography>
                )}
                {gender !== null && (
                    <Typography variant="body1" mt={2} gutterBottom>
                        Predicted Gender: {gender}
                    </Typography>
                )}
                <Box mt={2}>
                    <Button variant="contained" onClick={gender !== null ? handleAddAnother : handleFetchData}>
                        {gender !== null ? 'Predict Another' : 'Predict Gender'}
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
};
