/* eslint-disable import/no-anonymous-default-export */
import { CRMShow } from './CRMShow';
import { CRMList } from './CRMList';
import { CRMEdit } from './CRMEdit';
import { CRMCreate } from './CRMCreate';


export default {
    list: CRMList,
    show: CRMShow,
    edit: CRMEdit,
    create: CRMCreate,
};
