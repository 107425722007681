/* eslint-disable import/no-anonymous-default-export */
import { RegisterShow } from './RegisterShow';
import { RegisterList } from './RegisterList';
import { RegisterEdit } from './RegisterEdit';
import { RegisterCreate } from './RegisterCreate';


export default {
    list: RegisterList,
    show: RegisterShow,
    edit: RegisterEdit,
    create: RegisterCreate,
};
