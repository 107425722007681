import * as React from 'react';
import { CreateBase, Form, Toolbar, useGetIdentity } from 'react-admin';
import { Card, CardContent, Box, Avatar } from '@mui/material';

import { RegisterInputs } from './RegisterInputs';
import { RegRequests } from '../types';


export const RegisterCreate = () => {
    const { identity } = useGetIdentity();
    
    return <CreateBase
        redirect="show"
        transform={(data: RegRequests) => ({
            ...data,
            last_updated_at: new Date(),
            created_at: new Date(),
            user_id: identity?.id,
            tags: ["1"],
            status: "open",
        })}
    >
        <Box mt={2} display="flex">
            <Box flex="1">
                <Form>
                    <Card>
                        <CardContent>
                            <Box>
                                <Box display="flex">
                                    <Box mr={2}>
                                        <Avatar />
                                    </Box>
                                    <RegisterInputs />
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar />
                    </Card>
                </Form>
            </Box>
        </Box>
    </CreateBase>
}