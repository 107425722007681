import * as React from 'react';
import { TextField, MenuItem } from '@mui/material';

import { Status } from '../misc/Status';

export const StatusSelector = ({ status, setStatus, sx }: any) => {
    return (
        <TextField
            select
            value={status}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setStatus(event.target.value);
            }}
            variant="filled"
            label={false}
            margin="none"
            size="small"
            sx={sx}
        >
            <MenuItem value="open">
                Open <Status status="open" />
            </MenuItem>
            <MenuItem value="in-review">
                In Review   <Status status="in-review" />
            </MenuItem>
        </TextField>
    );
};
